/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 900;
  src: url("assets/fonts/Poppins-Black.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 800;
  src: url("assets/fonts/Poppins-ExtraBold.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 700;
  src: url("assets/fonts/Poppins-Bold.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 600;
  src: url("assets/fonts/Poppins-SemiBold.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 500;
  src: url("assets/fonts/Poppins-Medium.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 400;
  src: url("assets/fonts/Poppins-Regular.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 300;
  src: url("assets/fonts/Poppins-Light.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 200;
  src: url("assets/fonts/Poppins-ExtraLight.ttf") format("TrueType");
}

@font-face {
  font-family: "Poppins";
  font-display: auto;
  font-weight: 100;
  src: url("assets/fonts/Poppins-Thin.ttf") format("TrueType");
}


/* PrimeNG */
/* @import "assets/themes/lara/lara-light/gold/theme.scss"; */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Editor */
@import "../node_modules/quill/dist/quill.snow.css";

@import "assets/css/custom.css";
@import "assets/css/flag.css";

html,
body {
  font-size: 13px;
  font-family: "Poppins";
  margin: 0;
  padding: 0;
  /*color: var(--text-color-secondary);*/
}

@media (min-width: 768px) {

  html,
  body {
    font-size: 16px;
  }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

.w-400 {
  width: 400px;
  max-width: 100%;
}

.w-450 {
  width: 450px;
  max-width: 100%;
}

.text-grey {
  color: var(--n100);
}

.myqm-field {
  border: none;
  border-bottom: 1px solid var(--n100);

  input {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0;
    padding-right: 0;
    border: none;
    box-shadow: none;
  }

  input.input-clearable {
    // for input field that have clear input button
    padding-right: calc(15px + 0.5rem);
  }

  input::placeholder {
    font-weight: 400;
  }

  .div-clear-input {
    margin-left: calc(-1 * (15px + 0.5rem)); // Multiply -1 to get negative value
  
    .btn-clear-input {
      background: transparent;
    }
  }

  .p-dropdown {
    border: none;
    box-shadow: none;

    input {
      color: black;
      cursor: text;
    }

    .p-dropdown-trigger {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.p-float-label label {
  left: 0;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: var(--text-color-secondary);
}

/* Add webkit autofill selector to fix float label bug */
.p-float-label .p-inputwrapper:has(input:-webkit-autofill)  ~ label,
.p-float-label textarea:-webkit-autofill ~ label,
.p-float-label input:-webkit-autofill ~ label,
.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label {
  font-size: 12px;
  top: -0.5rem;
}

.p-button.btn-allow-disable:disabled {
  background-color: var(--n50);
  border-color: var(--n50);
  color: black;
  opacity: 1;
}

.p-button.font-medium {
  .p-button-label {
    font-weight: 500 !important;
  }
}

p-button.p-disabled:has(> button.btn-allow-disable) {
  opacity: 1;
}

.p-toast.toast_dark {
  .p-toast-message {
    border-radius: 1rem;

    .p-toast-message-content {
      background-color: var(--n300);
      color: white;
    }

    .p-toast-icon-close {
      display: none;
    }
  }
}

.p-checkbox-black.p-checkbox {
  .p-checkbox-box.p-highlight {
    border-color: black;
    background: black;
  }
}

.p-checkbox-black.p-checkbox:not(.p-checkbox-disabled) {
  .p-checkbox-box:hover {
    border-color: black;
  }

  .p-checkbox-box.p-highlight:hover {
    border-color: black;
    background: black;
    color: #ffffff;
  }

  .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0rem black;
    border-color: black;
  }
}

.p-radiobutton-disabled {
  cursor: auto;
}

.p-radiobutton-box.p-highlight {
  background: var(--n0);
  box-shadow: none;

  .p-radiobutton-icon {
    background-color: var(--primary-color);
  }
}

.dialog-guest-registered {
  .p-dialog-content {
    padding-bottom: 0;
    border-radius: 0.5rem !important;
  }
}

/**
 * START - Button Custom Primary
 */
 p-button.p-disabled:has(.btn-custom-primary) {
  opacity: 1;
}

.p-button.btn-custom-primary {
  background: var(--sg100);
  border: none;
  color: black;
  transition: none;
  font-weight: 600;

  &:disabled {
    background: var(--n70);
    opacity: 1;
    transition: none;
  }
}

.p-button.btn-custom-primary:enabled:hover,
.p-button.btn-custom-primary:enabled:focus {
  background: var(--sg100);
  color: black;
}
/**
 * END - Button Custom Primary
 */


/**
 * START - Button Custom Primary Outlined
 */
 p-button.p-disabled:has(.btn-custom-primary-outlined) {
  opacity: 1;
}

.p-button.btn-custom-primary-outlined {
  background-color: transparent;
  color: black;
  border: 1px solid var(--p300);

  &:disabled {
    opacity: 1;
    border-color: var(--n70);
    transition: none;
  }
}

.p-button.btn-custom-primary-outlined:enabled:hover,
.p-button.btn-custom-primary-outlined:enabled:focus {
  background: transparent;
  color: black;
  border: 1px solid var(--p300);
}
/**
 * END - Button Custom Primary Outlined
 */
